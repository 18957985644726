import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { default as BaseLink } from '../Link';

const Link = styled(BaseLink)`
  display: flex;
  &::before,
  &::after {
    content: none;
  }
`;

const logoImageQuery = graphql`
  query logoImageQuery {
    file(relativePath: {eq: "event-decorations-logo.png"}) {
      childImageSharp {
        fixed(width: 55, height: 55) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const Logo = ({ className = '' }) => {
  const data = useStaticQuery(logoImageQuery);

  return (
    <Link to="/">
      <Img fixed={data.file.childImageSharp.fixed} className={className} alt="logo" />
    </Link>
  );
};

export default Logo;
