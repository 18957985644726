import styled from 'styled-components';

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: ${(props) => props.hide ? '0' : '100%'};
  background: ${(props) => props.hide ? 'none' : props.theme.colors.grey.transparent};
  height: ${(props) => props.hide ? '0' : '100%'};
  transition: background 0.3s ease-in-out;
`;

export default Overlay;