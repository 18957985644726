import React from 'react';
import styled, { css } from 'styled-components';
import { Link as BaseLink } from 'gatsby';

import underlineHover from '../../styles/styled-components/reuseable-css/underline-hover';

const linkColour = css`
  display: inline-block;
  color: ${props => props.darkBackground ? props.theme.colors.white : props.theme.colors.primary};
  ${underlineHover};
  &::after {
    background: ${props => props.darkBackground ? props.theme.colors.white : props.theme.colors.primary};
  }

  &:hover,
  &:active {
    color: ${props => props.darkBackground ? props.theme.colors.white : props.theme.colors.primary};
  }
`;

const GatsbyLink = styled(({ darkBackground, ...props }) => <BaseLink {...props}/>)`
  ${linkColour}
`;

const ExternalLink = styled.a`
  ${linkColour}
`;
const Link = ({ children, to, activeClassName, partiallyActive, darkBackground = false, ...other }) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to);
  const file = /\.[0-9a-z]+$/i.test(to);

  if (internal) {
    if (file) {
      return (
        <ExternalLink href={to} {...other}>
          {children}
        </ExternalLink>
      )
    }
    return (
      <GatsbyLink
        darkBackground={darkBackground}
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    )
  }
  return (
    <ExternalLink href={to} {...other} target="_blank" rel="noopener noreferrer">
      {children}
    </ExternalLink>
  )
}

export default Link;