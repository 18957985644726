
/**
 * This is an auto generated file by the script in
 * scripts/createESModule.js
 * If you want to change this file
 * Please modify the file
 * ../src/styles/theme/antDesign
*/
export default {
  "primary": "#E1273F",
  "info": "#1E233A",
  "success": "#79884c",
  "processing": "#8DB3D0",
  "error": "#f44336",
  "warning": "#f77814",
  "white": "#fff",
  "black": "#000",
  "grey": {
    "transparent": "rgba(33, 33, 33, 0.45)",
    "transparentLight": "rgba(33,33,33,0.15)",
    "hex": "#D3D3D3"
  }
}
