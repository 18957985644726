import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import styled, { css } from 'styled-components';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import { Menu as BaseMenu } from 'antd';
import {
  HomeOutlined,
  ContactsOutlined,
  PictureOutlined,
  FileTextOutlined,
} from '@ant-design/icons';

import { default as baseUnderlineHover } from '../../styles/styled-components/reuseable-css/underline-hover';
import './submenu.less';

const { SubMenu: BaseSubMenu } = BaseMenu;

const Nav = styled.nav`
  flex-grow: 1;
  display: ${props => (props.mode === 'horizontal' ? 'none' : 'block')};
  ${({ theme }) => `
    ${theme.breakPoints.up('sm')} {
      display: block;
    }
  `}
`;

const Menu = styled(BaseMenu)`
  border-bottom: none;
  line-height: 3rem !important;
  &.ant-menu-inline {
    border-right: none;
  }
`;

const underlineHover = css`
  padding: 0 1.5rem;
  border: none !important;
  ${baseUnderlineHover};
`;

const SubMenu = styled(BaseSubMenu).attrs({ popupClassName: 'subMenuCustom' })`
  margin-top: -0.2rem !important;

  .ant-menu-submenu-title {
    padding: 0;
  }

  ${underlineHover};

  &.ant-menu-submenu-active {
    &::after {
      width: 100%;
    }
  }

  &.ant-menu-submenu-selected {
    &::after {
      width: 100%;
    }
  }

  &.ant-menu-submenu-inline {
    padding-left: 2.4rem; //Keep this value the same as '$\{SubMenu}.ant-menu-submenu-inline .ant-menu-item &' padding-left
    &::after {
      content: none;
    }
    .ant-menu-submenu-title {
      ${underlineHover};
      padding-left: 0 !important;
      overflow: initial;
    }
    .ant-menu-inline {
      background-color: ${props => props.theme.colors.primary};
    }
    &.ant-menu-submenu-open,
    &.ant-menu-submenu-active {
      > .ant-menu-submenu-title {
        &::after {
          width: 100%;
        }
      }
    }
  }
`;

const MenuItem = styled(Menu.Item)`
  border-bottom: none !important;
  margin-top: -0.2rem !important;
  ${underlineHover};
  &.ant-menu-item-selected {
    &::after {
      width: 100%;
    }
  }
  ${Menu}.ant-menu-inline & {
    padding-right: 0;
    margin-right: 16px;
    width: initial;
  }

  ${SubMenu}.ant-menu-submenu-inline .ant-menu-sub.ant-menu-inline & {
    padding-left: 2.4rem !important;
  }
`;

const iconMapping = {
  HomeOutlined: <HomeOutlined />,
  ContactsOutlined: <ContactsOutlined />,
  PictureOutlined: <PictureOutlined />,
  FileTextOutlined: <FileTextOutlined />,
};

const navigationQuery = graphql`
  query navigationData {
    markdownRemark(frontmatter: { type: { eq: "Navigation" } }) {
      frontmatter {
        navigation {
          icon
          link
          name
          menuItems {
            link
            name
          }
        }
      }
    }
  }
`;

const NavBar = ({ mode }) => {
  const {
    markdownRemark: {
      frontmatter: { navigation: navigationData },
    },
  } = useStaticQuery(navigationQuery);

  const getMenuItem = item => {
    return (
      <MenuItem
        key={uuidv4()}
        icon={iconMapping[item.icon]}
        onClick={() => navigate(item.link)}
      >
        {item.name}
      </MenuItem>
    );
  };

  const getSubMenu = item => {
    return (
      <SubMenu
        key={uuidv4()}
        icon={iconMapping[item.icon]}
        title={item.name}
        open={true}
      >
        {item.menuItems.map(menuItem => {
          if (menuItem.menuItems) return getSubMenu(menuItem);
          return getMenuItem(menuItem);
        })}
      </SubMenu>
    );
  };

  return (
    <Nav mode={mode}>
      <Menu mode={mode}>
        {navigationData.map(item => {
          if (item.menuItems) return getSubMenu(item);

          return getMenuItem(item);
        })}
      </Menu>
    </Nav>
  );
};

NavBar.propTypes = {
  mode: PropTypes.string.isRequired,
};

export default NavBar;
