import React from 'react';
import styled from 'styled-components';

const BurgerMenuContainer = styled.div`
  display: flex;
  width: 4rem;
  height: 2.2rem;
  align-items: center;
  cursor: pointer;

  & > div {
    position: relative;
    width: 100%;
    height: 2px;
    background-color: ${props => props.theme.colors.white};
    transition: all 0.4s ease;
    // top and bottom lines
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: -10px;
      width: 100%;
      height: 2px;
      background: inherit;
    }
    // moves one line down
    &:after {
      top: 10px;
    }
  }

  ${({ showCloseIcon }) => {
    if (!showCloseIcon) return null;
    return `
      height: 3.2rem;
      & > div {
        transform: rotate(135deg);

        &:before, &:after {
          top: 0;
          transform: rotate(90deg);
        }
      }
      &:hover > div {
        transform: rotate(225deg);
      }
    `;
  }}
`;

const BurgerMenuTrigger = ({ onClick, className, showCloseIcon = false }) => {
  return (
    <BurgerMenuContainer
      onClick={onClick}
      className={className}
      showCloseIcon={showCloseIcon}
    >
      <div />
    </BurgerMenuContainer>
  );
};

export default BurgerMenuTrigger;
