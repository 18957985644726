import { setTwoToneColor } from '@ant-design/icons';

import colors from './src/styles/theme/colors';

import './src/styles/_base.less';

export { default as wrapRootElement } from './wrapWithProviders';
export { default as wrapPageElement } from './wrapWithLayout';

export const onClientEntry = async () => {
  setTwoToneColor(colors.white);

  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }
};
