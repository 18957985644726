import breakPoints from './breakPoints';
import colors from './colors';
const keys = Object.keys(breakPoints);

const up = (key) => {
  const value = typeof breakPoints[key] === 'string' ? breakPoints[key] : `${key}px`;
  
  return `@media screen and (min-width: ${value})`;
}

const down = (key) => {
  const endIndex = keys.indexOf(key) + 1;
  const breakPointValue = breakPoints[key];

  if (endIndex === keys.length) {
    // xxl down applies to all sizes
    return theme.breakPoints.up('0');
  }

  const value = typeof breakPointValue === 'string' && endIndex > 0 ? breakPointValue : `${key}px`;
  return `@media screen and (max-width: ${value})`;
}

const theme = {
  breakPoints: {
    values: breakPoints,
    up,
    down
  },
  colors: {
    ...colors,
  }
}

export default theme;