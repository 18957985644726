import { css } from 'styled-components';

const underlineHover = css`
  &::after {
    border: none !important; // for when menu is inline mobile
    transform: none !important; // for when menu is inline mobile
    opacity: 1 !important; // for when menu is inline mobile
    top: initial !important; // for when menu is inline mobile
    right: initial !important; // for when menu is inline mobile
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: ${props => props.theme.colors.white};
    transition: width 0.3s !important;
  }

  &:hover,
  &:active {
    color: ${props => props.theme.colors.white};

    &::after {
      width: 100%;
    }
  }
`;

export default underlineHover;