import React, { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { Layout, Row, Col, Typography } from 'antd';

import Logo from '../Logo';
import { default as BaseLink } from '../Link';
import siteContentWidth from '../../styles/styled-components/reuseable-css/site-content-width';

const { Footer: AntFooter } = Layout;
const { Text: BaseText } = Typography;

const Link = styled(BaseLink)`
  display: block;
  width: fit-content;
  margin-bottom: 0.5rem;
  ${({ theme }) => `
    ${theme.breakPoints.down('xxs')} {
      font-size: 1.3rem;
    }
  `}
`;

const Text = styled(BaseText)`
  color: ${props => props.theme.colors.white}!important;
`;

const FooterContent = styled(Row)`
  color: ${props => props.theme.colors.white};
  padding: 2rem 0;

  ${({ theme }) => `
    ${theme.breakPoints.up('sm')} {
      flex-flow: row wrap;
    }
  `}

  ${siteContentWidth};
`;

const LogoColumn = styled(Col)`
  display: flex;
  justify-content: center;
`;

const footerQuery = graphql`
  query footerQuery {
    markdownRemark(frontmatter: { type: { eq: "Footer" } }) {
      frontmatter {
        footer {
          heading
          links {
            heading
            link
          }
        }
      }
    }
  }
`;

const Footer = () => {
  const {
    markdownRemark: {
      frontmatter: { footer: footerData },
    },
  } = useStaticQuery(footerQuery);

  const getLinks = useCallback(item => {
    const links = item.links.map(link => {
      return (
        <Link key={uuidv4()} to={link.link} darkBackground>
          {link.heading}
        </Link>
      );
    });

    return links;
  }, []);

  return (
    <AntFooter>
      <FooterContent gutter={[{ xs: 10, sm: 35 }, {xs: 10}]}>
        <LogoColumn
          span={24}
          sm={{ span: 4 }}
          lg={{ span: 3 }}
        >
          <Logo />
        </LogoColumn>
        {footerData.map((item) => {
          return (
            <Col
              key={uuidv4()}
              span={12}
              sm={{ span: 10 }}
              md={{ span: 7 }}
              lg={{ span: 5 }}
            >
              <Text underline strong>
                {item.heading}
              </Text>
              {getLinks(item)}
            </Col>
          );
        })}
      </FooterContent>
    </AntFooter>
  );
};

export default Footer;
