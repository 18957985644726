import React, { useState, useCallback, useEffect } from 'react';
import { globalHistory } from '@reach/router';
import styled from 'styled-components';
import { Layout as BaseLayout } from 'antd';

import Overlay from '../Overlay';
import NavBar from '../NavBar';
import Footer from '../Footer';
import CookieModal from '../CookieModal';
import { default as BaseLogo } from '../Logo';
import { default as BaseBurgerMenuTrigger } from '../BurgerMenuTrigger';
import siteContentWidth from '../../styles/styled-components/reuseable-css/site-content-width';

const { Header, Content: BaseContent, Sider } = BaseLayout;

const Container = styled(BaseLayout)`
  min-height: 100vh; // To push footer to the bottom when not enough content
`;

const HeadContent = styled.div`
  position: relative;
  display: flex;
  height: inherit;
  align-items: center;

  ${siteContentWidth};
`;

const Content = styled(BaseContent)`
  // To push footer to the bottom when not enough content
  flex: 1 0 auto;
  ${siteContentWidth};
`;

const Logo = styled(BaseLogo)`
  z-index: 1;
`;

const BurgerMenuTrigger = styled(BaseBurgerMenuTrigger)`
  position: absolute;
  z-index: 1;
  right: 0;
  ${({ theme }) => `
    ${theme.breakPoints.up('sm')} {
      display: none;
    }
  `}
`;

const MenuSider = styled(Sider)`
  position: absolute;
  right: 0;
  height: 100vh;
  overflow: hidden;
  ${({ collapsed }) => `
    ${!collapsed &&
      `
      flex: 0 0 100%!important;
      max-width: 100%!important;
      min-width: 100%!important;
      width: 100%!important;
    `}
  `}

  ${({ theme, collapsed }) => `
    ${theme.breakPoints.up('xxs')} {
      ${!collapsed &&
        `
        flex: 0 0 80%!important;
        max-width: 80%!important;
        min-width: 80%!important;
        width: 80%!important;
      `}
    }
    ${theme.breakPoints.up('sm')} {
      display: none;
    }
  `}

  .ant-layout-sider-zero-width-trigger {
    display: none;
  }

  .ant-layout-sider-children {
    padding-top: 6.4rem; //This is to match the header size
  }
`;

const Layout = ({ children }) => {
  const [isMobileMenuClosed, setIsMobileMenuClosed] = useState(true);
  const closeMobileMenu = useCallback(() => {
    setIsMobileMenuClosed(true);
  }, []);

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') closeMobileMenu();
    });
  }, [closeMobileMenu]);

  useEffect(() => {
    if (!isMobileMenuClosed) {
      document.body.style.position = 'fixed';
      document.body.style.top = '0';
    } else {
      document.body.style.position = 'initial';
    }
  }, [isMobileMenuClosed]);

  return (
    <>
      <Container hasSider>
        <Container>
          <Header>
            <HeadContent>
              <Logo />
              <NavBar mode="horizontal" />
              <BurgerMenuTrigger
                onClick={_ => setIsMobileMenuClosed(!isMobileMenuClosed)}
                showCloseIcon={!isMobileMenuClosed}
              />
            </HeadContent>
          </Header>
          <Content>{children}</Content>
          <Footer />
          <CookieModal />
        </Container>
        <Overlay hide={isMobileMenuClosed} onClick={closeMobileMenu} />
        <MenuSider
          breakpoint="sm"
          defaultCollapsed={true}
          collapsedWidth="0"
          collapsed={isMobileMenuClosed}
          onBreakpoint={broken => {
            if (!broken) closeMobileMenu();
          }}
          width="80%"
        >
          <NavBar mode="inline" />
        </MenuSider>
      </Container>
    </>
  );
};

export default Layout;
