import { css } from 'styled-components';

const siteContentWidth = css`
  margin: 0 1.5rem!important;

  ${({ theme }) => `
    ${theme.breakPoints.up('sm')} {
      margin: 0 2.5rem!important;
    }
    ${theme.breakPoints.up('xl')} {
      max-width: ${theme.breakPoints.values.xl}!important;
      width: ${theme.breakPoints.values.xl}!important;
      margin: 0 auto!important;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  `}
`;

export default siteContentWidth;