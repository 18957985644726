import React, { useState, useRef, useCallback } from 'react';
import styled, { css } from 'styled-components';
import Cookies from 'js-cookie';
import { Modal as BaseModal, Typography } from 'antd';

import Link from '../Link';

const { Title, Paragraph } = Typography;

const modalPosition = css`
  top: initial;
  right: initial;
`;

const Modal = styled(BaseModal)`
  ${modalPosition};
  padding-bottom: 0;
  .ant-modal-content {
    border-radius: 3rem;
    box-shadow: 0 0 1rem 0 ${props => props.theme.colors.grey.transparent};
  }
`;

const CookieModal = ({ className }) => {
  const cookieContainer = useRef(null);
  const cookiePolicyAcknowledged = Boolean(
    Cookies.get('cookiePolicyAcknowledged')
  );
  const [isOpen, setIsOpen] = useState(!cookiePolicyAcknowledged);

  const closeModal = useCallback(() => {
    setIsOpen(false);
    Cookies.set('cookiePolicyAcknowledged', true, {
      expires: 1,
      sameSite: 'strict',
    });
  }, [setIsOpen]);

  const getContainer = useCallback(() => {
    return cookieContainer.current;
  }, [cookieContainer]);

  return (
    <>
      <div ref={cookieContainer} />
      <Modal
        visible={isOpen}
        onCancel={closeModal}
        destroyOnClose
        footer={null}
        mask={false}
        maskClosable={true}
        wrapClassName={className}
        getContainer={getContainer}
      >
        <Title level={4}>Cookies on this website</Title>
        <Paragraph>
          We use cookies to help ensure that our website and services are able to
          function properly. These cookies are necessary and so are set
          automatically.
          <br />
          To learn more about how we use cookies, visit our{' '}
          <Link to="/policies/cookie-policy">Cookie notice</Link>.
        </Paragraph>
      </Modal>
    </>
  );
};

const StyledCookieModal = styled(CookieModal)`
  ${modalPosition};
  border-radius: 3rem;
  padding: 1rem;
`;

export default StyledCookieModal;
