
/**
 * This is an auto generated file by the script in
 * scripts/createESModule.js
 * If you want to change this file
 * Please modify the file
 * ../src/styles/theme/antDesign
*/
export default {
  "xxs": "380px",
  "xs": "480px",
  "sm": "576px",
  "md": "768px",
  "lg": "992px",
  "xl": "1200px",
  "xxl": "1600px"
}
