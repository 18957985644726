import React from 'react';
import { ThemeProvider } from 'styled-components';

import theme from './src/styles/theme/styledComponents';

const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      {element}
    </ThemeProvider> 
  )
}

export default wrapRootElement